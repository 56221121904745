/* .json-table {
    width: 100%;
    border-collapse: collapse;
    font-family: Arial, sans-serif;
  }
  
  .json-table th,
  .json-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }
  
  .json-table th {
    background-color: #f2f2f2;
    font-weight: bold;
  }
  
  .json-table tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  .json-table tr:hover {
    background-color: #ddd;
  }
   */

   .json-table {
    width: 100%;
    border-collapse: collapse;
    font-family: Arial, sans-serif;
  }
  
  .json-table th,
  .json-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }
  
  .json-table th {
    background-color: #f2f2f2;
    font-weight: bold;
  }
  
  .json-table tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  .json-table tr:hover {
    background-color: #ddd;
  }
  
  .json-table a {
    color: #1a73e8;
    text-decoration: none;
  }
  
  .json-table a:hover {
    text-decoration: underline;
  }
  